import { createContext, useContext } from 'react';
import ApiService from './api.js';

const BASE_URL = process.env.REACT_APP_API_URL;

export const defaultApiService = new ApiService(BASE_URL);

export const ApiContext = createContext(defaultApiService);

export function useApi() {
    return useContext(ApiContext);
}
