import axios from 'axios';
const DEFAULT_INIT_DATA = ''; // `query_id=AAG-rSwaAAAAAL6tLBp1rLxW&user=%7B%22id%22%3A439135678%2C%22first_name%22%3A%22nong%22%2C%22last_name%22%3A%22piseth%22%2C%22username%22%3A%22nong_piseth%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1674099813&hash=c73f62af368a88b678e12cb30a1017eed5f9f5f98d819330b8f4d91006fa3d4f`
const appId = process.env.REACT_APP_APP_ID;

export default class ApiService {
    _telegram = null;
    _apiUrl = null;
    /**
     * @type {import('axios').Axios}
     */
    _client = null;

    constructor(apiUrl) {
        this._telegram = typeof window !== 'undefined' ? window.Telegram : null;
        this._apiUrl = apiUrl;
        this._client = axios.create({
            baseURL: apiUrl,
        });
    }

    get _telegramInitData() {
        if (!this._telegram) return;
        return this._telegram.WebApp.initData;
    }

    _headers(object = {}) {
        const initData = this._telegramInitData || DEFAULT_INIT_DATA;
        const initDataHeader = { appid: appId, 'custom-lang': localStorage.getItem('lng') || 'km' };
        if (initData) initDataHeader['X-Telegram-InitData'] = initData;
        return {
            ...initDataHeader,
            ...object,
        };
    }

    searchCertificateWithLetterNumber(
        examCenter = '0',
        room = '0',
        seat = '0',
        dateOfBirth = '',
        telegramUserId = '',
        letterNumber = '0',
        examYear = 2023
    ) {
        const data = {
            examCenter: examCenter,
            seat: seat,
            room: room,
            telegramUserId: telegramUserId,
            dateOfBirth: dateOfBirth,
            letterNumber: letterNumber,
            yearOfExam: examYear,
        };
        return this.post('/api/v2/telegram/request-certificate-by-exam-letter', data);
    }

    searchCertificateWithMotherSurName(
        examCenter = '0',
        room = '0',
        seat = '0',
        dateOfBirth = '',
        telegramUserId = '',
        motherSurName,
        examYear = 2023
    ) {
        const data = {
            examCenter: examCenter,
            seat: seat,
            room: room,
            telegramUserId: telegramUserId,
            dateOfBirth: dateOfBirth,
            motherFamily: motherSurName,
            yearOfExam: examYear,
        };
        return this.post('/api/v2/telegram/request-certificate-by-exam', data);
    }
    /**
     * @param {string} path
     * @param {Record<string, string>} headers
     */
    get(path, headers = {}) {
        const _headers = this._headers(headers);
        const abortController = new AbortController();

        const cancel = () => abortController.abort();
        const request = async () => {
            const { data } = await this._client.get(path, {
                headers: _headers,
                signal: abortController.signal,
            });
            return data;
        };

        return { cancel, request };
    }

    /**
     * @param {string} path
     * @param {any} body
     * @param {Record<string, string>} headers
     */
    post(path, body, headers = {}) {
        const _headers = this._headers(headers);
        const abortController = new AbortController();
        const cancel = () => abortController.abort();
        const request = async () => {
            const { data } = await this._client.post(path, body, {
                headers: _headers,
                signal: abortController.signal,
            });

            return data;
        };
        return { cancel, request };
    }
}
