import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotFound from '../components/notfound/Notfound';

import { pdfjs, Document, Page } from 'react-pdf';

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import * as moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import InputMask from 'react-input-mask';
import MuiAlert from '@mui/material/Alert';
import { telegram } from '../telegram.js';
import { useApi } from '../services/context.js';
import LetterNumber from '../components/letternumberdialog/LetterNumber';
import ToggleButtonSwitch from '../components/toggleButtonSwitch/ToggleButtonSwitch';
import MotherInfo from '../components/motherInfo/MotherInfo';
import { hasCertificate, hasNoCertificate, notChoose } from '../util/staticdata';
import styles from '../components/toggleButtonSwitch/ToggleButtonSwitch.module.css';
import { AssignmentReturnRounded } from '@mui/icons-material';

const provinceData = require('../data/province');
const examCenterData = require('../data/exam-center');
const examCenterData2023 = require('../data/exam-center-2023');

const Home = () => {
    const { t, i18n } = useTranslation();
    const [province, setProvince] = useState(1);
    const [examCenterNumber, setExamCenterNumber] = useState('');
    const [roomNumber, setRoomNumber] = useState('');
    const [seatNumber, setSeatNumber] = useState('');
    const [letterNumber, setLetterNumber] = useState('');
    const [motherSurName, setMotherSurName] = useState('');
    const [dob, setDoB] = useState('');
    const [pdfData, setPdfData] = useState('');
    const [isLoading, setIsLoading] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isFound, setIsFound] = useState(true);
    const [examCenterList, setExamCenters] = useState([]);
    const provinceArray = JSON.parse(JSON.stringify(provinceData));
    const examCenterArray = JSON.parse(JSON.stringify(examCenterData));
    const examCenterArray2023 = JSON.parse(JSON.stringify(examCenterData2023));
    const api = useApi();
    const [user, setUser] = useState(null);
    const [initData, setInitData] = useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [severity, setSeverity] = useState('warning');
    const [isFormValid, setIsFormValid] = useState(false);
    const [toggleSwitch, setToggleSwitch] = useState(notChoose);
    const [snackBarText, setSnackBarText] = useState(t('sent_certificate_in_telegram'));
    const years = ['2023', '2022'];
    const [examYear, setExamYear] = useState(null);
    useEffect(() => {
        initExamCenter();
        if (telegram().WebApp) {
            const { user } = telegram().WebApp.initDataUnsafe || {};
            const initData = telegram().WebApp.initData;
            telegram().WebApp.expand();
            setUser(user);
            setInitData(initData);
            setUser(user);
            setInitData(initData);
        }
    }, []);
    const initExamCenter = () => {
        let defaultProvince = provinceArray[0];
        setProvince(defaultProvince.ProvinceCode);
        let examcenters = filterExamCenter(defaultProvince.ProvinceCode);
        setExamCenters(examcenters);
    }
    const fullName = (user) =>
        user ? (
            <h5>
                {user.first_name} {user.last_name}
            </h5>
        ) : null;
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const populateMessage = (response) => {
        setIsLoading(false);
        setIsFound(true);
        if (response.success === true) {
            if (response.data != null) {
                if (response.data.status === 1) {
                    setSeverity('success');
                } else {
                    setSeverity('warning');
                }
                setOpenSnackbar(true);
                setSnackBarText(response.data.message);
            }
        }
    };

    const filterExamCenter = (value) => {
        if (examYear == 2023) {
            return examCenterArray2023.filter((item) => item.ProvinceCode === value);
        } else {
            return examCenterArray.filter((item) => item.ProvinceCode === value);
        }
    };

    const handleChangeInutDoB = (e) => {
        let value = e.target.value;
        setDoB(value);
    };

    const selectProvince = (e) => {
        let province = e.target.value;
        setProvince(province);
        let examcenters = filterExamCenter(parseInt(province));
        setExamCenters(examcenters);
    };

    const selectExamCenter = (e) => {
        let select = e.target.value;
        setExamCenterNumber(select);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const IsValidInput = () => {
        var formatDate = moment(dob, 'DD-MM-YYYY');

        if (toggleSwitch === hasCertificate) {
            let isValidLetterNumber =
                examCenterNumber !== '' &&
                roomNumber !== '' &&
                seatNumber !== '' &&
                dob !== '' &&
                letterNumber !== '' &&
                letterNumber.length === 5 &&
                formatDate.isValid();
            setIsFormValid(isValidLetterNumber);
            return isValidLetterNumber;
        } else if (toggleSwitch === hasNoCertificate) {
            let isValidMother =
                examCenterNumber !== '' &&
                roomNumber !== '' &&
                seatNumber !== '' &&
                dob !== '' &&
                motherSurName !== '' &&
                motherSurName.length > 0 &&
                formatDate.isValid();
            setIsFormValid(isValidMother);
            return isValidMother;
        } else {
            setIsFormValid(false);
            return false;
        }
    };
    const startSearching = () => {
        setPdfData('');
        setIsStartSearch(true);
        setIsLoading(true);
        setIsFound(true);
    };
    const notFoundData = () => {
        setIsLoading(false);
        setIsFound(false);
        setOpenSnackbar(true);
        setSeverity('warning');
        setSnackBarText(t('message_not_found_detail'));
    };
    const inValidMessage = () => {
        setOpenSnackbar(true);
        setSeverity('warning');
        setSnackBarText(t('enter_certificate_info'));
    };
    function getDataCertificateByExamWithDoBLetterNumber(
        examCenterNumber,
        roomNumber,
        seatNumber,
        dateOfBirth,
        telegramUserId,
        letterNumber,
        examYear
    ) {
        api.searchCertificateWithLetterNumber(
            examCenterNumber.trim(),
            roomNumber.trim(),
            seatNumber.trim(),
            dateOfBirth.trim(),
            telegramUserId,
            letterNumber.trim(),
            examYear.trim()
        )
            .request()
            .then((res) => {
                populateMessage(res);
            })
            .catch((error) => {
                notFoundData();
            });
    }

    function getDataCertificateByExamWithDoBMotherSurName(
        examCenterNumber,
        roomNumber,
        seatNumber,
        dateOfBirth,
        telegramUserId,
        motherSurName,
        examYear
    ) {
        api.searchCertificateWithMotherSurName(
            examCenterNumber.trim(),
            roomNumber.trim(),
            seatNumber.trim(),
            dateOfBirth.trim(),
            telegramUserId,
            motherSurName.trim(),
            examYear.trim()
        )
            .request()
            .then((res) => {
                populateMessage(res);
            })
            .catch((error) => {
                notFoundData();
            });
    }
    const searchByLetterNumber = () => {
        var formatDate = moment(dob, 'DD-MM-YYYY');
        var dateOfBirth = formatDate.format('YYYY-MM-DD');
        if (IsValidInput()) {
            startSearching();
            getDataCertificateByExamWithDoBLetterNumber(
                examCenterNumber,
                roomNumber,
                seatNumber,
                dateOfBirth,
                `${user ? user?.id : ''}`,
                letterNumber,
                examYear
            );
        } else {
            inValidMessage();
        }
    };

    const searchByMotherSurName = () => {
        var formatDate = moment(dob, 'DD-MM-YYYY');
        var dateOfBirth = formatDate.format('YYYY-MM-DD');
        if (IsValidInput()) {
            startSearching();
            getDataCertificateByExamWithDoBMotherSurName(
                examCenterNumber,
                roomNumber,
                seatNumber,
                dateOfBirth,
                `${user ? user?.id : ''}`,
                motherSurName,
                examYear
            );
        } else {
            inValidMessage();
        }
    };
    const onSearch = () => {
        setOpenSnackbar(false);
        if (toggleSwitch === hasCertificate) {
            searchByLetterNumber();
        }
        if (toggleSwitch === hasNoCertificate) {
            searchByMotherSurName();
        }
    };
    const twoDigit = (n) => {
        return n > 9 ? '' + n : '0' + n;
    };
    const onHandleChangeRoom = (e) => {
        if (isNumeric(e.target.value)) {
            setRoomNumber(e.target.value);
        } else setRoomNumber(null);
    };
    const onHandleChangeSeat = (e) => {
        if (isNumeric(e.target.value)) {
            setSeatNumber(e.target.value);
        } else setSeatNumber(null);
    };
    const isNumeric = (num) => {
        num = '' + num; //coerce num to be a string
        return !isNaN(num) && !isNaN(parseFloat(num));
    };

    const onHangleChangeLetterNumber = (e) => {
        if (isNumeric(e.target.value)) {
            setLetterNumber(e.target.value);
        } else setLetterNumber(null);
    };
    const handleToggleChange = (event, newAlignment) => {
        setToggleSwitch(newAlignment);
    };
    const onChangeMotherSurname = (e) => {
        setMotherSurName(e.target.value);
    };

    const onYearChanged = (event, value) => {
        setExamYear(value);
        initExamCenter();
        if (value === '2023') setToggleSwitch(hasCertificate);
        else setToggleSwitch(notChoose);
    };
    return (
        <>
            <div className="container">
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        sx={{ width: '100%' }}
                        severity={severity}
                        anchorOrigin={{
                            horizontal: 'center',
                        }}
                    >
                        {snackBarText}
                    </Alert>
                </Snackbar>
                <div className="formbox">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
                            <div className={styles.toggleBox + ' ' + styles.mt12}>
                                <ToggleButtonGroup
                                    value={examYear}
                                    exclusive
                                    fullWidth
                                    size="small"
                                    className={styles.toggleToggle}
                                    onChange={onYearChanged}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton
                                        value={years[0]}
                                        aria-label="left aligned"
                                        classes={{ selected: styles.buttonColor }}
                                    >
                                        {t('examYear')} {years[0]}
                                    </ToggleButton>
                                    <ToggleButton
                                        value={years[1]}
                                        aria-label="right aligned"
                                        classes={{ selected: styles.buttonColor }}
                                    >
                                        {t('examYear')} {years[1]}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {examYear && <span className={styles.divider}></span>}
                            </div>
                        </div>
                    </div>
                    {examYear === '2022' && (
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
                                <ToggleButtonSwitch
                                    value={toggleSwitch}
                                    handleToggleChange={handleToggleChange}
                                ></ToggleButtonSwitch>
                            </div>
                        </div>
                    )}
                    {(toggleSwitch === hasCertificate || toggleSwitch === hasNoCertificate) && (
                        <>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <Box sx={{ minWidth: 120 }} className="forminput">
                                        <FormControl fullWidth>
                                            <InputLabel id="province" size="small">
                                                {t('province_city')}
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                required
                                                variant="outlined"
                                                labelId="province"
                                                id="province-select"
                                                value={`${province}`}
                                                label={t('province_city')}
                                                onChange={selectProvince}
                                            >
                                                {provinceArray?.map((p, index) => (
                                                    <MenuItem
                                                        key={'name-shared' + p.ProvinceCode}
                                                        value={`${p.ProvinceCode}`}
                                                    >
                                                        {twoDigit(index + 1)} -{' '}
                                                        {i18n.language === 'km' ? p.NameKh : p.NameEn}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <Box sx={{ minWidth: 120 }} className="forminput">
                                        <FormControl fullWidth>
                                            <InputLabel id="examcenter" size="small">
                                                {t('search_by_exam_center')}
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                required
                                                variant="outlined"
                                                labelId="examcenter"
                                                id="examcenter-select"
                                                value={`${examCenterNumber}`}
                                                label={t('search_by_exam_center')}
                                                onChange={selectExamCenter}
                                            >
                                                {examCenterList?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={`${item.Id}`}>
                                                            {twoDigit(index + 1)} - {item.Name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                                    <InputMask
                                        sx={{ width: '100%' }}
                                        mask="99"
                                        maskChar=" "
                                        min={1}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        type={'number'}
                                        onChange={onHandleChangeRoom}
                                        label={t('room_number')}
                                        value={roomNumber}
                                    >
                                        {() => (
                                            <TextField
                                                required
                                                size="small"
                                                name="letter_number"
                                                className="forminput"
                                                sx={{ input: { color: 'black', width: '100%' } }}
                                                label={t('room_number')}
                                                type="text"
                                            />
                                        )}
                                    </InputMask>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                                    <InputMask
                                        sx={{ width: '100%' }}
                                        mask="9999"
                                        maskChar=" "
                                        min={1}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        type={'number'}
                                        onChange={onHandleChangeSeat}
                                        label={t('seat_number')}
                                        className="forminput"
                                        value={seatNumber}
                                    >
                                        {() => (
                                            <TextField
                                                required
                                                size="small"
                                                name="letter_number"
                                                className="forminput"
                                                sx={{ input: { color: 'black', width: '100%' } }}
                                                label={t('seat_number')}
                                                type="text"
                                            />
                                        )}
                                    </InputMask>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                                    <InputMask
                                        sx={{ width: '100%' }}
                                        mask="99-99-9999"
                                        maskChar="#"
                                        alwaysShowMask={true}
                                        onChange={handleChangeInutDoB}
                                        label={'DD-MM-YYYY'}
                                        placeholder="DD-MM-YYYY"
                                        value={dob}
                                    >
                                        {() => (
                                            <TextField
                                                required
                                                size="small"
                                                name="expire"
                                                className="forminput dateinput"
                                                sx={{ input: { color: 'black', width: '100%' } }}
                                                label={t('date_of_birth')}
                                                helperText="DD-MM-YYYY"
                                                type="text"
                                            />
                                        )}
                                    </InputMask>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                                    {toggleSwitch === hasCertificate && (
                                        <>
                                            <InputMask
                                                sx={{ width: '100%' }}
                                                mask="99999"
                                                maskChar="#"
                                                min={0}
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                type={'number'}
                                                onChange={onHangleChangeLetterNumber}
                                                label={t('letter_number')}
                                                value={letterNumber}
                                            >
                                                {() => (
                                                    <TextField
                                                        required
                                                        size="small"
                                                        name="letter_number"
                                                        className="forminput latterNumber"
                                                        sx={{ input: { color: 'black', width: '100%' } }}
                                                        label={t('letter_number')}
                                                        type="text"
                                                    />
                                                )}
                                            </InputMask>
                                            <LetterNumber></LetterNumber>
                                        </>
                                    )}
                                    {toggleSwitch === hasNoCertificate && (
                                        <>
                                            <TextField
                                                required
                                                size="small"
                                                name="monther_family_name"
                                                className="forminput latterNumber"
                                                sx={{ input: { color: 'black', width: '100%' } }}
                                                onChange={onChangeMotherSurname}
                                                label={t('monther_family_name')}
                                                type="text"
                                            />
                                            <MotherInfo></MotherInfo>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 centerbox">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className="search"
                                        startIcon={<SearchIcon />}
                                        onClick={onSearch}
                                    >
                                        {t('search')}
                                    </Button>
                                </div>
                                {isLoading === true && (
                                    <div className="loadingBox">
                                        <CircularProgress />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
